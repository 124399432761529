<template>
  <div class="row animated fadeIn">
<!--    <div class="col-lg-12 mb-1 d-flex flex-column">-->
<!--      <Table-pagination-v2-->
<!--        :on-submit="getWithQuery.bind(this)"-->
<!--        v-model="filters"-->
<!--        :show-filters="['profile', 'location', 'date']"-->
<!--        :options="tablePaginationOptions"-->
<!--      ></Table-pagination-v2>-->
<!--    </div>-->

    <div class="col-lg-12">
      <div class="row">
                <div class="col-lg-3 mb-h input-group-for-location-select-in-PortalLocationsStat--filters">

                                                                                                           <span
                                                                                                             class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location.data}"></i>
                </span>
          <SelectComponentV3ForUseInLocationSelectors

            v-if="showFilters"
            no-wrap-selected-option
            class="select-location-in-PortalLocationsStat--filters"
            enable-max-option-width
            small-size
            :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
            :show-delete-button="!isSelectedDefaultLocationForRequests"
            v-model="filters.location.data"
            track-by="name"
            option-id-name="id"
            :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
            :no-options-found="$t('general.noResultForSearch')"
            :async-function="requestLocations.bind(this)"
            :placeholder="$t('portalStats.selectLocation')"
            @select="selectLocation"
          >
            <template #dropdown-start>
              <div class="ml-h mt-1">
                <Switch-component
                  v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                  @input="toggleWithChilds"
                  :label="$t('general.withChild')"
                  class=""
                  v-model="filters.location.with_childs"
                />
              </div>
            </template>
          </SelectComponentV3ForUseInLocationSelectors>


        </div>
        <div class="col-lg-3">
          <div class="input-group input-group-sm w-100">
                        <span class="input-group-addon">
              <i class="fa fa-file-o" />
            </span>
                            <Multi-select-component
                              v-if="showFilters"
                    v-model="filters.profiles"
                    class="multiselect-in-PortalLocationsStat--filters w-100"
                    option-id-name="id"
                    track-by="name"
                    :placeholder="$t('portalStats.selectProfile')"
                    :async-function="requestProfiles.bind(this)"
                    @select="selectProfileInselector"
                  />
            </div>
        </div>
      </div>
          <div class="row ">
      <div class="col-lg-4">
        <div class="filters__multiple">
          <div class="input-group input-group-sm filters__wlan flex-wrap mr-1">
            <WButton secondary outline sm
              v-if="isFiltersActive"
              @click.stop.prevent="resetAllFilters"
            >
              <i class="fa fa-close"></i>
              {{ $t('general.resetAllFilters') }}
            </WButton>
          </div>
        </div>
      </div>
      </div>
    </div>


    <div class="col-lg-12">
      <div class="card">
        <!--        <div class="loader loader-backdrop loader-backdrop-portaluseraccounts" v-if="isPortalLocationsStatLoading"></div>-->
        <div v-if="isPortalLocationsStatLoading"><Loader-spinner /></div>
        <div class="card-header d-flex align-items-center justify-content-between">
          <div>
            <i class="fa fa-globe mr-0"></i>
            {{ $t('portalLocationsStat.locationsStat') }}
            <!--            <span class="text-muted ml-3" v-if="Object.keys(this.locationsStatList).length">-->
            <!--              {{ Object.keys(this.locationsStatList).length }}-->
            <!--            </span>-->

          </div>
          <div class="d-flex align-items-center">
                        <div class="float-right">
                          <div class="mr-1" style="min-width: 300px;">
                    <div class="input-group input-group filters__location">
                      <date-picker
                        v-if="showFilters"
                        class="datepicker_width_full datepicker_height_40"
                        v-model="filters.date"
                        type="date"
                        :onChange="handleDateChangeForStat.bind(this)"
                      />
                    </div>
            </div>
            </div>
            <!--            <download-extract-v2-->
            <!--              v-if="!isPortalLocationsStatLoading && locationsStatList && locationsStatList.length > 0"-->
            <!--              class="ml-h"-->
            <!--              :data="formatPortalUserAccounts()"-->
            <!--              :data-for-excel="formatPortalUserAccountToExcel()"-->
            <!--              :excel-file-name="`portal-accounts-${moment(new Date()).format('lll')}`"-->
            <!--              :filename="`portal-accounts-${moment(new Date()).format('lll')}`"-->
            <!--              :downloads-formats-for-show="{-->
            <!--                oldCsv: false,-->
            <!--                newCsv: true,-->
            <!--                oldXls: false,-->
            <!--                newXls: true,-->
            <!--                pdf: true-->
            <!--              }"-->
            <!--            />-->
          </div>
        </div>
        <div class="card-block card-block__table table-responsive table-with-loc-stats-wrapper">
          <!-- <h5 class="mb-1"></h5> -->
          <!-- <div class="row">
            <div class="col-lg-12"> -->
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col" class="table-headers-wrap-portal-locations-stat align-middle">
                  {{ $t('portalLocationsStat.location') }}
                </th>
                <th scope="col" class="table-headers-wrap-portal-locations-stat align-middle">
                  {{ $t('portalLocationsStat.clicks') }}
                </th>
                <th scope="col" class="table-headers-wrap-portal-locations-stat align-middle">
                  {{ $t('portalLocationsStat.views') }}
                </th>
                <th scope="col" class="table-headers-wrap-portal-locations-stat align-middle">
                  {{ $t('portalLocationsStat.allVisits') }}
                </th>
                <th scope="col" class="table-headers-wrap-portal-locations-stat align-middle">
                  {{ $t('portalLocationsStat.uniqueVisit') }}
                </th>
                <th scope="col" class="table-headers-wrap-portal-locations-stat align-middle">
                  {{ $t('portalLocationsStat.firstVisit') }}
                </th>
                <th scope="col" class="table-headers-wrap-portal-locations-stat align-middle">
                  {{ $t('portalLocationsStat.repeatVisit') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="locId in locationsStatSortedLocIdList" :key="locId">
                <td class="align-middle">{{ getLocationNameByLocId(locId) }}</td>
                <td class="align-middle">
                  <span
                    v-if="
                      getLocationStatInfoByLocId(locId).ad_stat &&
                      getLocationStatInfoByLocId(locId).ad_stat.hasOwnProperty('clicks')
                    "
                  >
                    {{ getLocationStatInfoByLocId(locId).ad_stat.clicks }}
                  </span>
                  <EmptyCellValue v-else />
                </td>
                <td class="align-middle">
                  <span
                    v-if="
                      getLocationStatInfoByLocId(locId).ad_stat &&
                      getLocationStatInfoByLocId(locId).ad_stat.hasOwnProperty('views')
                    "
                  >
                    {{ getLocationStatInfoByLocId(locId).ad_stat.views }}
                  </span>
                  <EmptyCellValue v-else />
                </td>
                <td class="align-middle">
                  <span
                    v-if="
                      getLocationStatInfoByLocId(locId).clients_stat &&
                      getLocationStatInfoByLocId(locId).clients_stat.hasOwnProperty('all_visits')
                    "
                  >
                    {{ getLocationStatInfoByLocId(locId).clients_stat.all_visits }}
                  </span>
                  <EmptyCellValue v-else />
                </td>
                <td class="align-middle">
                  <span
                    v-if="
                      getLocationStatInfoByLocId(locId).clients_stat &&
                      getLocationStatInfoByLocId(locId).clients_stat.hasOwnProperty('unique_visit')
                    "
                  >
                    {{ getLocationStatInfoByLocId(locId).clients_stat.unique_visit }}
                  </span>
                  <EmptyCellValue v-else />
                </td>
                <td class="align-middle">
                  <span
                    v-if="
                      getLocationStatInfoByLocId(locId).clients_stat &&
                      getLocationStatInfoByLocId(locId).clients_stat.hasOwnProperty('first_visit')
                    "
                  >
                    {{ getLocationStatInfoByLocId(locId).clients_stat.first_visit }}
                  </span>
                  <EmptyCellValue v-else />
                </td>
                <td class="align-middle">
                  <span
                    v-if="
                      getLocationStatInfoByLocId(locId).clients_stat &&
                      getLocationStatInfoByLocId(locId).clients_stat.hasOwnProperty('repeat_visit')
                    "
                  >
                    {{ getLocationStatInfoByLocId(locId).clients_stat.repeat_visit }}
                  </span>
                  <EmptyCellValue v-else />
                </td>
              </tr>
              <tr class="table-with-loc-stats-total-row" v-if="Object.keys(this.locationsStatList).length > 1">
                <td class="align-middle">
                  <b>{{ $t('portalLocationsStat.total') }}</b>
                </td>
                <td class="align-middle">
                  <b>
                    {{ locationsStatTotal.ad_stat.clicks }}
                  </b>
                </td>
                <td class="align-middle">
                  <b>
                    {{ locationsStatTotal.ad_stat.views }}
                  </b>
                </td>
                <td class="align-middle">
                  <b>
                    {{ locationsStatTotal.clients_stat.all_visits }}
                  </b>
                </td>
                <td class="align-middle">
                  <b>
                    {{ locationsStatTotal.clients_stat.unique_visit }}
                  </b>
                </td>
                <td class="align-middle">
                  <b>
                    {{ locationsStatTotal.clients_stat.first_visit }}
                  </b>
                </td>
                <td class="align-middle">
                  <b>
                    {{ locationsStatTotal.clients_stat.repeat_visit }}
                  </b>
                </td>
              </tr>
              <tr v-if="Object.keys(this.locationsStatList).length === 0">
                <td colspan="7" class="no-data-row">
                  <span class="text-muted">{{ $t('general.noDataToDisplay') }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmptyCellValue from '../../components/empty-cell-value.vue';

import portalProfilesService from '../../services/portalProfilesService';
import portalLocationsStatService from '../../services/portalLocationsStatService';
import locationService from '../../services/locationService';
// import TablePaginationV2 from '../../components/table-pagination-v2.vue';
import DatePicker from '../../components/date-picker.vue';
import SelectComponentV2 from '../../components/Universal/select/select-componentV2.vue';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import MultiSelectComponent from '../../components/Universal/select/multi-select-component.vue';
import SelectComponentV3ForUseInLocationSelectors from '../../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';

export default {
  name: 'PortalLocationsStat',
  components: {
    EmptyCellValue,
    // TablePaginationV2,
    DatePicker,
    SelectComponentV2,
    SwitchComponent,
    MultiSelectComponent,
    SelectComponentV3ForUseInLocationSelectors
  },
  data() {
    return {
      showFilters: true,
      selectedProfileInPortalUserAccounts: { name: this.$t('portalStats.allProfiles'), id: '' },
      selectedLocationInPortalUserAccounts: { name: this.$t('portalStats.allLocations'), id: '' },
      isShow: false,
      selectedProfileInMultiselect: [],
      filters: {
        location: {
          data: {},
          with_childs: true
        },
        profiles: [],
        date: {
          // последние 7 дней
          startDate: moment().startOf('day').valueOf() - 7 * 24 * 60 * 60 * 1000, // семь дней
          endDate: moment().endOf('day').valueOf()
        }
      }
    };
  },
  methods: {
    async requestProfiles(searchQuery) {
      // return portalProfilesService.requestProfiles(searchQuery);
      return portalProfilesService.requestProfilesFilteredByLocationAndWithChilds(this, searchQuery);
    },
    redrawFilters(){
      this.showFilters = false;
      this.$nextTick(()=>{
        this.showFilters = true;
      })
    },
    // resetAllFilters() {
    //   this.selectedProfileInMultiselect = [];
    //   this.filters = {
    //     location: {
    //       data: {},
    //       with_childs: true
    //     },
    //     profiles: [],
    //     date: {
    //       // последние 7 дней
    //       startDate: moment().startOf('day').valueOf() - 7 * 24 * 60 * 60 * 1000, // семь дней
    //       endDate: moment().endOf('day').valueOf()
    //     }
    //   }
    //
    //   this.redrawFilters();
    //   this.getWithQuery();
    // },
    resetAllFilters() {
      this.selectedProfileInMultiselect = [];
      this.filters = {
        location: {
          // data: {},
          // with_childs: true
        },
        profiles: [],
        date: {
          // последние 7 дней
          startDate: moment().startOf('day').valueOf() - 7 * 24 * 60 * 60 * 1000, // семь дней
          endDate: moment().endOf('day').valueOf()
        }
      }
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = {};
        this.filters.location.data = {};
        this.filters.location.with_childs = true;
      } else {
        this.filters.location = {};
        this.filters.location.data = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
        this.filters.location.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
      }

      this.redrawFilters();
      this.getWithQuery();
    },
    selectLocation(location) {
      if (!location) {
        this.filters.location.data = {};
      }
      this.getWithQuery();
    },
    handleDateChangeForStat() {
      this.getWithQuery()
    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    getLocationNameByLocId(locId) {
      if (this.locationsListByLocId.hasOwnProperty(locId)) {
        return this.locationsListByLocId[locId].name;
      }
      return locId;
    },
    getLocationStatInfoByLocId(locId) {
      return this.locationsStatList[locId];
    },
    selectProfileInselector(){
       this.getWithQuery()
    },
    // async requestProfiles(searchQuery) {
    //   return portalProfilesService.requestProfiles(searchQuery);
    // },
    toggleWithChilds() {
      // this.filterLocation.with_childs = !this.filterLocation.with_childs;
      this.getWithQuery()
    },
    clearQuery() {
      // this.offset = 0;
      // this.filters.offset = 0;
      // this.canLoadMoreAccounts = true;
      this.$store.commit('portalLocationsStatListClean');
    },

    getWithQuery() {
      this.clearQuery();
      portalLocationsStatService.getLocationsStat(this, { query: true });
    }
  },

  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    locationsStatTotal() {
      const result = {
        ad_stat: {
          clicks: 0,
          views: 0
        },
        clients_stat: {
          all_visits: 0,
          first_visit: 0,
          repeat_visit: 0,
          unique_visit: 0
        }
      };
      for (const locationId in this.locationsStatList) {
        const location = this.locationsStatList[locationId];
        result.ad_stat.clicks += location.ad_stat.clicks;
        result.ad_stat.views += location.ad_stat.views;
        result.clients_stat.all_visits += location.clients_stat.all_visits;
        result.clients_stat.first_visit += location.clients_stat.first_visit;
        result.clients_stat.repeat_visit += location.clients_stat.repeat_visit;
        result.clients_stat.unique_visit += location.clients_stat.unique_visit;
      }
      return result;
    },
    isFiltersActive() {
      return (typeof this.filters.location.data === 'object' && Object.keys(this.filters.location.data).length) ||
        (Array.isArray(this.filters.profiles) && this.filters.profiles.length !== 0) ||
        this.filters.date.startDate !== moment().startOf('day').valueOf() - 7 * 24 * 60 * 60 * 1000 ||
        this.filters.date.endDate !== moment().endOf('day').valueOf()
    },
    isDisabledWithChilds() {
      if (this.selectedLocationInPortalUserAccounts.id === '') {
        return true;
      }
      return false;
    },
    selectedProfileInMultiselectForRequest() {
      const result = [];
      if (this.selectedProfileInMultiselect.length === 0) {
        return result;
      }
      for (const profile of this.selectedProfileInMultiselect) {
        result.push(profile.id);
      }
      return result;
    },
    tablePaginationOptions() {
      if (this.showBalanceAndTransactions) {
        return [
          { value: 'profile', label: this.$t('userAccount.profile') },
          { value: 'identity', label: this.$t('userAccount.username') },
          { value: 'balance', label: this.$t('userAccount.balance') },
          { value: 'name', label: this.$t('userAccount.name') }
        ];
      }
      return [
        { value: 'profile', label: this.$t('userAccount.profile') },
        { value: 'identity', label: this.$t('userAccount.username') },
        { value: 'name', label: this.$t('userAccount.name') }
      ];
    },
    locationsStatList() {
      return this.$store.state.portalLocationsStatList;
    },
    locationsStatSortedLocIdList() {
      // тут храним loc_id локаций, по которым есть статистика в отсортированном порядке
      const result = [];
      for (const location of this.locationsListSorted) {
        if (this.locationsStatList.hasOwnProperty(location.loc_id)) {
          result.push(location.loc_id);
        }
      }
      return result;
    },
    locationsListSorted() {
      const locationsForSort = JSON.parse(JSON.stringify(this.locationsList));
      const result = locationsForSort.sort((curr, prev) => {
        if (curr.name < prev.name) {
          return -1;
        }
        if (curr.name > prev.name) {
          return 1;
        }
        return 0;
      });
      return result;
    },
    locationsList() {
      return this.$store.state.locationsList;
    },
    locationsListByLocId() {
      const result = {};
      for (const location of this.locationsList) {
        result[location.loc_id] = location;
      }
      return result;
    },
    isPortalLocationsStatLoading() {
      return this.$store.state.loadingPortalLocationsStat;
    }
  },
  // created() {
  //   // this.$store.commit('portalLocationsStatListClean');
  //   this.clearQuery();
  //   // portalLocationsStatService.getLocationsStat(this, { query: true });
  //   // portalProfilesService.getPortalProfiles(this);
  // }
    created() {
    // this.$store.commit('portalLocationsStatListClean');
    this.clearQuery();
    // portalLocationsStatService.getLocationsStat(this, { query: true });
    // portalProfilesService.getPortalProfiles(this);

      let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }
      if (locationForFiltering === 'All locations') {
        this.filters.location.data = {};
        this.filters.location.with_childs = true;
      } else {
        this.filters.location.data = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
        this.filters.location.with_childs = withChildsForFiltering
      }
      portalLocationsStatService.getLocationsStat(this, { query: true });
    }


  }
};
</script>

<style lang="scss">
    .download-button-in-PortalLocationsStat button{
        height: 40px !important;
    width: 100%;
  }
  .multiselect-in-PortalLocationsStat--filters {
  min-height: 23px !important;
}

.multiselect-in-PortalLocationsStat--filters .multi-select__control {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  max-height: 300px !important;
  min-height: 23px !important;
  align-items: center;
}

.multiselect-in-PortalLocationsStat--filters .multi-select__input {
  min-height: 23px !important;
  margin-bottom: 0px !important;
}

.multiselect-in-PortalLocationsStat--filters .arrow {
  min-height: 23px !important;
}

.multiselect-in-PortalLocationsStat--filters .multi-select__tag {
  margin-bottom: 0px;
  height: 22px !important;
}

    .input-group-for-location-select-in-PortalLocationsStat--filters {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;
    max-width: 100%;
    z-index: 9997 !important;
  }

  .select-location-in-PortalLocationsStat--filters .select__list-container {
    width: 400px;
  }
.datepicker-height-27-in-portal-location-stat {
  height: 27px !important;
  color: rgb(148, 160, 178);
}
.loader-backdrop-portaluseraccounts:after {
  position: absolute;
  top: 30px;
}
.half-width {
  width: 46%;
}
.text-center {
  text-align: center;
}
.cool-modern-selector input {
  display: none !important;
}

.cool-modern-selector input[type='radio'] + label,
.cool-modern-selector input[type='checkbox'] + label {
  display: inline-block;
  padding-right: 20px;
  border-radius: 6px;
  background: #dddddd;
  color: #939393;
  height: 35.5px;
  line-height: 35.5px;
  text-align: center;
  cursor: pointer;
  /* margin-top: 3px;
  margin-bottom: 3px; */
  user-select: none;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}

.cool-modern-selector input[type='radio']:checked + label,
.cool-modern-selector input[type='checkbox']:checked + label {
  background: #4dbd74;
  color: #ffffff;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}
</style>
<style lang="scss" scoped>
.table-with-loc-stats-wrapper {
  display: block;
  overflow-y: auto;
  max-height: calc(100vh - 335px);
}
.table-with-loc-stats-wrapper table {
  margin-bottom: 0;
}
.table-with-loc-stats-wrapper thead th {
  position: sticky;
  top: 0;
  background-color: white;
  border: 0px;
}
.table-with-loc-stats-wrapper .table-with-loc-stats-total-row td {
  position: sticky;
  bottom: 0px;
  background-color: rgba(236, 236, 236, 1);
}
.table-headers-wrap-portal-locations-stat {
  word-break: break-word;
  word-wrap: break-word;
}
.disbale-with-childs {
  cursor: not-allowed;
}

.custom-width-for-location-filter-left {
  width: 85% !important;
}
.custom-width-for-location-filter-right {
  width: 15% !important;
}
.location-filter-with-child-caption {
  max-height: 1em;
  min-width: 200px;
}
.custom-width-for-profile-select-in-portal-accounts {
  width: 50%;
}

.bottom-margin-for-multiselect {
  margin-bottom: 0.65rem;
}
</style>
